import axios from "../axios.config";
import Swal from "sweetalert2";

const state = {
  user_group: [],
  email_config: {},
  email_config_save: {}
};

const actions = {
  async loadUserGroup({ commit }) {
    try {
      const { data } = await axios.get("/user/group");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_USER_GROUP", data.data[0]);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadEmailConfig({ commit }) {
    try {
      const { data } = await axios.get("/company_email");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_EMAIL_CONFIG", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async testConnectionEmail({ commit }, params) {
    try {
      const { data } = await axios.post(`/company_email/test_connection`, params);
      if (!data.success) {
        await Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: data.message
        });
        return;
      }

      await Swal.fire({
        icon: 'success',
        title: data.message
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateEmailConfig({ commit }, params) {
    try {
      commit("SET_SAVE", {});
      const { data } = await axios.put(`/company_email/` + params.id, params);
      if (!data.success) {
        await Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: data.message
        });
        return;
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteEmailConfig({ commit }, params) {
    try {
      commit("SET_SAVE", {});
      const { data } = await axios.delete(`/company_email/` + params.id, params);
      if (!data.success) {
        await Swal.fire({
          icon: 'error',
          title: 'Erro',
          html: data.message
        });
        return;
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_USER_GROUP: (state, user_group) => {
    state.user_group = user_group;
  },

  SET_EMAIL_CONFIG: (state, email_config) => {
    state.email_config = email_config;
  },

  SET_SAVE: (state, email_config) => {
    state.email_config_save = email_config;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
