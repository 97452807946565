<template>
  <v-app>
    <top-bar v-if="isAuthenticated" />
    <nav-bar v-if="isAuthenticated && !loading" :logout="logout" />
    <v-main class="mt-2 backgroud">
      <v-container fluid class="mt-0 pt-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Token from "@/helpers/token.helper";
import TopBar from "./layouts/TopBar";
import NavBar from "./layouts/NavBar";
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {
    TopBar,
    NavBar
  },
  data() {
    return {
      drawer: false,
      loading: true
    };
  },
  async mounted() {
    await this.loadActiveMenu().catch((error) => {
      this.$swal("Oops..", error.message, "error");
    });
    await this.loadLoggedUser().catch((error) => {
      this.$swal("Oops..", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    isAuthenticated() {
      return Token.hasToken();
    }
  },
  methods: {
    ...mapActions("menu", ["loadActiveMenu"]),
    ...mapActions("user", ["loadLoggedUser"]),
    logout() {
      this.$router.push("/login");
    }
  }
};
</script>

<style>
/*.primary_btn {*/
/*  background: linear-gradient(177.98deg, #f5796a 1.7%, #ef3f3a 122.46%);*/
/*}*/
</style>
