import axios from "../axios.config";

const state = {
  syncEmailList: [],
  syncedEmailList: [],
  openedSolicitationList: [],
  syncedSolicitation: [],
  syncEmail: [],
  unsyncEmail: [],
  searchResult: []
};

const actions = {
  async loadEmailList({ commit }) {
    try {
      const { data } = await axios.get("/email/disassociated");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_EMAIL_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSyncedEmailList({ commit }) {
    try {
      const { data } = await axios.get("/email/associated");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SYNCED_EMAIL_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadOpenedSolicitationList({ commit }, search) {
    try {
      let params = new URLSearchParams();
      params.append('page', search.page);
      params.append('per_page', search.per_page);
      params.append('search', search.search || '');
      params.append('sort_by', search.sort_by || '');
      params.append('sort_order', search.sort_order || '');

      const { data } = await axios.get("/solicitation", { params });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_OPENED_SOLICITATION_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSyncedSolicitation({ commit }, emailId) {
    try {
      const { data } = await axios.post(`email/sol_associated`, emailId);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SYNCED_SOLICITATION", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async syncEmailAction({ commit, dispatch }, param) {
    try {
      const { data } = await axios.post(`email/associate_sol`, param);
      if (data.success === false) {
        throw new Error(data.message);
      }
      dispatch("loadEmailList");
      commit("setSyncEmail", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async unsyncEmailAction({ commit }, param) {
    try {
      const { data } = await axios.post("email/disassociate_sol", param);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setUnsyncEmail", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSearchSolicitationContent({ commit }, content) {
    try {
      const { data } = await axios.post("filter_solicitation", {
        search: content
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setSolicitationSearchContent", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};
const mutations = {
  SET_EMAIL_LIST: (state, emailList) => {
    state.syncEmailList = emailList;
  },

  SET_SYNCED_EMAIL_LIST: (state, syncedEmailList) => {
    state.syncedEmailList = syncedEmailList;
  },

  SET_OPENED_SOLICITATION_LIST: (state, solicitationList) => {
    state.openedSolicitationList.data = solicitationList.data.solicitation.data;
  },

  SET_SYNCED_SOLICITATION: (state, syncedSolicitation) => {
    state.syncedSolicitation = syncedSolicitation;
  },

  setSyncEmail: (state, syncEmail) => {
    state.syncEmail = syncEmail;
  },

  setUnsyncEmail: (state, unsyncEmail) => {
    state.unsyncEmail = unsyncEmail;
  },

  setSolicitationSearchContent: (state, content) => {
    state.searchResult = content;
  }
};
const getters = {};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
